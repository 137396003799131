import React from "react";
import { useRoutes } from "react-router-dom";
import Forgot from "../auth/Forgot";
import Login from "../auth/Login";
import Register from "../auth/Register";
import Reset from "../auth/Reset";
import Verify from "../auth/Verify";

const AuthRouter = () => {
  return useRoutes([
    { path: "*", element: <Login /> },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/forgot",
      element: <Forgot />,
    },
    {
      path: "/reset",
      element: <Reset />,
    },

    {
      path: "/verify",
      element: <Verify />,
    },
  ]);
};

export default AuthRouter;
