import React from "react";
import { Stack } from "react-bootstrap";

const Section = ({ title, link, side, classes, desc, children, noGutter }) => (
  <div className="card p-2">
    {title && (
      <Stack direction="horizontal" className={`justify-content-between align-items-center ${noGutter ? "mb-0" : "mb-2"}`}>
        <div className="card-header d-flex flex-column">
          {desc && <span className="text-g fs-xs">{desc}</span>}
          {title && <h5 className="card-title">{title}</h5>}
        </div>
        <div className="card-body">{side}</div>
      </Stack>
    )}

    {children}
  </div>
);

export default Section;
