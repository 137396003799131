import React from "react";
import "./ResellerAPI.css";
import Section from "../../components/Section";
import Table from "../../components/Table";
import Header from "../../components/Header";
import { useAuthStore } from "../../stores/AuthStore";

export default function ResellerAPI(props) {
  const user = useAuthStore((state) => state.user);
  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      WeShipSmart RESELLER API
                    </h3>
                  </div>
                </div>
              </div>

              {/* api key  */}
              <div className="border-btm pb-3 pt-2">
                <Section title="API Key">
                  <Table headers={["Key", "Value"]} maxW="420px">
                    <tr>
                      <td>API Key</td>
                      <td>
                        <span className="text-warning">{user.api_key}</span>
                      </td>
                    </tr>
                  </Table>
                </Section>
              </div>

              <div className="border-btm pb-3 pt-2">
                <Section title="Variables">
                  <Table headers={["Key", "Value", "Type"]} maxW="420px">
                    <tr>
                      <td>base_url</td>
                      <td>
                        <a href={"#"}>{"https://api.ezyship.org/api/v2"}</a>
                      </td>
                      <td>string</td>
                    </tr>
                  </Table>
                </Section>
              </div>
              <div className="border-btm pb-3 pt-2">
                <Section title="Authorization">
                  <Table headers={["Key", "Value", "Add to"]} maxW="400px">
                    <tr>
                      <td>x-api-key</td>
                      <td>Your API Key</td>
                      <td>Header</td>
                    </tr>
                  </Table>
                </Section>
              </div>
              <div className="border-btm pb-3 pt-2">
                <Section title="1. Read LabelTypes">
                  {/* <h4>1. Read LabelTypes</h4> */}
                  <span className="text-g">Endpoint:</span>
                  <pre className="papr mt-2">
                    <code className="language-bash">
                      Method: GET
                      {"\n"}URL: {"{"}
                      {"{"}base_url{"}"}
                      {"}"}/labeltype/read-types{"\n"}
                    </code>
                  </pre>
                  <span className="text-g">Example Request</span>
                  <pre className="papr mt-2">
                    <code
                      dangerouslySetInnerHTML={{
                        __html: `var axios = require('axios');
var config = {
  method: 'get',
  maxBodyLength: Infinity,
  url: 'https://api.ezyship.org/api/v2/labeltype/read-types',
  headers: { 
    'x-api-key': 'YOUR-API-KEY'
  }
};

axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
  })
  .catch(function (error) {
    console.log(error);
  });`,
                      }}></code>
                  </pre>
                  <span className="text-g">Example Response</span>

                  <pre className="papr mt-2">
                    <code
                      dangerouslySetInnerHTML={{
                        __html: `[
  {
    "name": "USPS Priority",
    "uid": "priority",
    "maxWeight": 30,
    "status": true,
    "prices": [
      {
        "price": 3.5,
        "fromWeight": 1,
        "toWeight": 60,
        "_id": "6wqeqw3213qweqwe7c7qweqwad864"
      }
    ]
  }
]`,
                      }}></code>
                  </pre>
                </Section>
              </div>
              <div className="border-btm pb-3 pt-2">
                <Section title="2. Create Order">
                  <span className="text-g">Endpoint:</span>
                  <pre className="papr mt-2">
                    <code className="language-bash">
                      Method: POST{"\n"}Type: URLENCODED{"\n"}URL: {"{"}
                      {"{"}base_url{"}"}
                      {"}"}/order/create-order{"\n"}
                    </code>
                  </pre>

                  <div className="text-g mb-2">Body:</div>
                  <div className="row  g-5">
                    <div className="col-12">
                      <Table
                        headers={["Key", "Example Value", "Type", "Required"]}
                        maxW="500px">
                        <tr>
                          <td>labelType</td>
                          <td>priority</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>Weight</td>
                          <td>2</td>
                          <td>Number</td>
                          <td>Yes</td>
                        </tr>

                        <tr>
                          <td>Height</td>
                          <td>2</td>
                          <td>Number</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>Length</td>
                          <td>2</td>
                          <td>Number</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>Width</td>
                          <td>2</td>
                          <td>Number</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td className="text-success">CallbackUrl</td>
                          <td>https://mydomain/callback</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                      </Table>
                    </div>

                    <div className="col-12 col-md-6">
                      <Table
                        headers={["Key", "Example Value", "Type", "Required"]}>
                        <tr>
                          <td>FromCountry</td>
                          <td>US</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>FromName</td>
                          <td>Majd Bigham</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>FromCompany</td>
                          <td>ExampleCompanyA</td>
                          <td>String</td>
                          <td>(Optional)</td>
                        </tr>
                        <tr>
                          <td>FromPhone</td>
                          <td>1234567890</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td className="text-success">FromStreet1</td>
                          <td>6609 Steen St</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>FromStreet2</td>
                          <td>ExampleStreet2</td>
                          <td>String</td>
                          <td>(Optional)</td>
                        </tr>
                        <tr>
                          <td>FromZip</td>
                          <td>43110</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>FromCity</td>
                          <td>Canal Winchester</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>FromState</td>
                          <td>OH</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                      </Table>
                    </div>
                    <div className="col-12 col-md-6">
                      <Table
                        headers={["Key", "Example Value", "Type", "Required"]}>
                        <tr>
                          <td>ToCountry</td>
                          <td>US</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>ToName</td>
                          <td>Dino DeRoia</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>ToCompany</td>
                          <td>ExampleCompanyB</td>
                          <td>String</td>
                          <td>(Optional)</td>
                        </tr>
                        <tr>
                          <td>ToPhone</td>
                          <td>1234567890</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td className="text-success">ToStreet1</td>
                          <td>7965 Princewood Dr</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>ToStreet2</td>
                          <td>ExampleStreet2</td>
                          <td>String</td>
                          <td>(Optional)</td>
                        </tr>
                        <tr>
                          <td>ToZip</td>
                          <td>44236-1589</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>ToCity</td>
                          <td>Hudson</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>ToState</td>
                          <td>OH</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                      </Table>
                    </div>
                  </div>

                  <div className="text-g my-2">Example Request</div>
                  <pre className="papr mt-2">
                    <code
                      dangerouslySetInnerHTML={{
                        __html: `
var axios = require('axios');
var data = {   
    "labelType": "priority",
    "Weight": 2,
    "CallbackUrl": "https://mydomain/callback",
    "FromCountry": "US",
    "FromName": "Majd Bigham",
    "FromCompany": "",
    "FromPhone": "",
    "FromStreet1": "6609 Steen St",
    "FromStreet2": "",   
    "FromZip": "43110",    
    "FromCity": "Canal Winchester",    
    "FromState": "OH",    
    "ToCountry": "US",    
    "ToName": "Dino DeRoia",    
    "ToCompany": "",    
    "ToPhone": "",    
    "ToStreet1": "7965 Princewood Dr",    
    "ToStreet2": "",   
    "ToZip": "44236",   
    "ToCity": "Hudson",   
    "ToState": "OH",   
    "Height": 2,   
    "Length": 2,   
    "Width": 2\n};                       
var config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://api.ezyship.org/api/v2/order/create-order',
  headers: { 
    'x-api-key': 'YOUR-API-KEY'
  },
  data : data
};

axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
  })
  .catch(function (error) {
    console.log(error);
  });
`,
                      }}></code>
                  </pre>

                  <span className="text-g text-success">
                    Example Success Response 200
                  </span>
                  <pre className="papr mt-2">
                    <code
                      dangerouslySetInnerHTML={{
                        __html: `{
  "type": "success",
  "message": "Order added to queue"
}`,
                      }}></code>
                  </pre>
                  <span className="text-g text-danger">
                    Example failed Response 400
                  </span>
                  <pre className="papr mt-2">
                    <code
                      dangerouslySetInnerHTML={{
                        __html: `{
  "type": "failed",
  "message": "message here"
}`,
                      }}></code>
                  </pre>
                </Section>
              </div>
              {/* Single order webhook response */}
              <div className="border-btm pb-3 pt-2">
                <Section title="3. Webhook response on CallbackUrl (Order)">
                  {/* <h4>1. Read LabelTypes</h4> */}
                  <span className="text-g">Endpoint:</span>
                  <pre className="papr mt-2">
                    <code className="language-bash">
                      Method: POST
                      {"\n"}URL:
                      {"{"}CallbackUrl{"}"} provided in the request while
                      creating single label
                    </code>
                  </pre>

                  <span className="text-g text-success">
                    Example Response Success
                  </span>
                  <pre className="papr mt-2">
                    <code
                      dangerouslySetInnerHTML={{
                        __html: `{
  status: 'completed',
  uuid: 'uuid',
  OrderId: "orderId",
  message: 'message here',
  pdf: 'pdf url',
  tracking: "tracking number"
}`,
                      }}></code>
                  </pre>
                  <span className="text-g text-danger">
                    Example Response Failed
                  </span>
                  <pre className="papr mt-2">
                    <code
                      dangerouslySetInnerHTML={{
                        __html: `{
  status: 'cancelled',
  uuid: '',
  OrderId: "",
  message: 'message here',
  pdf: '',
  tracking: ""
}`,
                      }}></code>
                  </pre>
                </Section>
              </div>
              <div className="border-btm pb-3 pt-2">
                <Section title="4. Read Orders">
                  {/* <h4>1. Read LabelTypes</h4> */}
                  <span className="text-g">Endpoint:</span>
                  <pre className="papr mt-2">
                    <code className="language-bash">
                      Method: GET
                      {"\n"}URL: {"{"}
                      {"{"}base_url{"}"}
                      {"}"}/order/read-orders{"\n"}
                    </code>
                  </pre>
                  <span className="text-g">Example Request</span>
                  <pre className="papr mt-2">
                    <code
                      dangerouslySetInnerHTML={{
                        __html: `var axios = require('axios');
var config = {
  method: 'get',
  maxBodyLength: Infinity,
  url: 'https://api.ezyship.org/api/v2/order/read-orders',
  headers: { 
    'x-api-key': 'YOUR-API-KEY'
  }
};

axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
  })
  .catch(function (error) {
    console.log(error);
  });
`,
                      }}></code>
                  </pre>

                  <span className="text-g">Example Response</span>
                  <pre className="papr mt-2">
                    <code
                      dangerouslySetInnerHTML={{
                        __html: `{
  "_id": "65a0e1f5b1b30e8a0d7f4f",
  "labelType": {
    "_id": "65a0e1f5b1b30e8a0d7f4e",
    "name": "USPS Ground Advantage(OZ)",
    "uid": "ground_oz"
  },
  "price": 6.5,
  "tracking": "92001582328670068308081334",
  "pdf": "./labels/65a0e1f5b1b30e8a0d7f4f/920015823weqw2321328081334.pdf",
  "OrderId": "65a0e1f5b1b30e8a0d7f4d",
  "uuid": "65a0e1f5b1b30e8a0d7f4d",
  "user": "65a0e1f5b1b30e8a0d7f4c",
  "createdAt": "2023-09-25T14:45:00.000Z",
  "updatedAt": "2023-09-25T14:45:00.000Z",
  "__v": 3
}`,
                      }}></code>
                  </pre>
                </Section>
              </div>
              <div className="border-btm pb-3 pt-2">
                <Section title="5. Download Orders">
                  {/* <h4>1. Read LabelTypes</h4> */}
                  <span className="text-g">Endpoint:</span>
                  <pre className="papr mt-2">
                    <code className="language-bash">
                      Method: GET
                      {"\n"}URL: {"{"}
                      {"{"}base_url{"}"}
                      {"}"}/order/download/
                      <span className="text-warning">
                        {"{"}
                        {"{"}
                        order_uuid
                        {"}"}
                        {"}"}
                      </span>
                      {"\n"}
                    </code>
                  </pre>
                  <span className="text-g my-2">Params</span>
                  <Table
                    headers={["Parameter", "Description", "Required"]}
                    maxW="500px">
                    <tr>
                      <td>
                        <span className="text-warning">
                          {"{"}
                          {"{"}order uuid{"}"}
                          {"}"}
                        </span>
                      </td>
                      <td>ID of order to be downloaded</td>
                      <td>Yes</td>
                    </tr>
                  </Table>
                  <span className="text-g my-2">Example Request</span>
                  <pre className="papr ">
                    <code
                      dangerouslySetInnerHTML={{
                        __html: `var axios = require('axios');
var config = {
  method: 'get',
  maxBodyLength: Infinity,
  url: 'https://api.ezyship.org/api/v2/order/download/64f4c32b038da2adf2229aa8',
  headers: { 
    'x-api-key': 'YOUR-API-KEY'
  }
};

axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
  })
  .catch(function (error) {
    console.log(error);
  });
`,
                      }}></code>
                  </pre>
                </Section>
                <Section title="6. Create Bulk Order">
                  {/* <h4>1. Read LabelTypes</h4> */}
                  <span className="text-g">Endpoint:</span>
                  <pre className="papr mt-2">
                    <code className="language-bash">
                      Method: GET
                      {"\n"}URL: {"{"}
                      {"{"}base_url{"}"}
                      {"}"}/create-bulk-order
                      {"\n"}
                    </code>
                  </pre>

                  <div className="text-g mb-2">Body:</div>
                  <div className="row  g-5">
                    <div className="col-12">
                      <Table
                        headers={["Key", "Example Value", "Type", "Required"]}
                        maxW="500px">
                        <tr>
                          <td>labelType</td>
                          <td>priority</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>data</td>
                          <td>[ ]</td>
                          <td>Array</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td className="text-success">CallbackUrl</td>
                          <td>https://mydomain/callback</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>Weight</td>
                          <td>2</td>
                          <td>Number</td>
                          <td>Yes</td>
                        </tr>

                        <tr>
                          <td>Height</td>
                          <td>2</td>
                          <td>Number</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>Length</td>
                          <td>2</td>
                          <td>Number</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>Width</td>
                          <td>2</td>
                          <td>Number</td>
                          <td>Yes</td>
                        </tr>
                      </Table>
                    </div>

                    <div className="col-12 col-md-6">
                      <Table
                        headers={["Key", "Example Value", "Type", "Required"]}>
                        <tr>
                          <td>FromCountry</td>
                          <td>US</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>FromName</td>
                          <td>Majd Bigham</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>FromCompany</td>
                          <td>ExampleCompanyA</td>
                          <td>String</td>
                          <td>(Optional)</td>
                        </tr>
                        <tr>
                          <td>FromPhone</td>
                          <td>1234567890</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td className="text-success">FromStreet1</td>
                          <td>6609 Steen St</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>FromStreet2</td>
                          <td>ExampleStreet2</td>
                          <td>String</td>
                          <td>(Optional)</td>
                        </tr>
                        <tr>
                          <td>FromZip</td>
                          <td>43110</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>FromCity</td>
                          <td>Canal Winchester</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>FromState</td>
                          <td>OH</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                      </Table>
                    </div>
                    <div className="col-12 col-md-6">
                      <Table
                        headers={["Key", "Example Value", "Type", "Required"]}>
                        <tr>
                          <td>ToCountry</td>
                          <td>US</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>ToName</td>
                          <td>Dino DeRoia</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>ToCompany</td>
                          <td>ExampleCompanyB</td>
                          <td>String</td>
                          <td>(Optional)</td>
                        </tr>
                        <tr>
                          <td>ToPhone</td>
                          <td>1234567890</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td className="text-success">ToStreet1</td>
                          <td>7965 Princewood Dr</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>ToStreet2</td>
                          <td>ExampleStreet2</td>
                          <td>String</td>
                          <td>(Optional)</td>
                        </tr>
                        <tr>
                          <td>ToZip</td>
                          <td>44236-1589</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>ToCity</td>
                          <td>Hudson</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>ToState</td>
                          <td>OH</td>
                          <td>String</td>
                          <td>Yes</td>
                        </tr>
                      </Table>
                    </div>
                  </div>
                  <span className="text-g mt-2">Example Request</span>
                  <pre className="papr mt-2">
                    <code
                      dangerouslySetInnerHTML={{
                        __html: `var axios = require('axios');
var data = '{\n    "labelType": "priority",\n    "CallbackUrl": "https://mydomain/callback",\n    "data": [\n        {\n            "Weight": 2,\n            "FromCountry": "US",\n            "FromName": "Majd Bigham",\n            "FromCompany": "",\n            "FromPhone": "",\n            "FromStreet1": "6609 Steen St",\n            "FromStreet2": "",\n            "FromZip": "43110",\n            "FromCity": "Canal Winchester",\n            "FromState": "OH",\n            "ToCountry": "US",\n            "ToName": "Dino DeRoia",\n            "ToCompany": "",\n            "ToPhone": "",\n            "ToStreet1": "7965 Princewood Dr",\n            "ToStreet2": "",\n            "ToZip": "44236-1589",\n            "ToCity": "Hudson",\n            "ToState": "OH",\n            "Height": 2,\n            "Length": 2,\n            "Width": 2\n        }\n    ]\n}';
                        
var config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://api.ezyship.org/api/v2/order/create-bulk-order',
  headers: { 
    'x-api-key': 'YOUR-API-KEY'
  },
  data : data
};

axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
  })
  .catch(function (error) {
    console.log(error);
  });                    
`,
                      }}></code>
                  </pre>

                  <span className="text-g text-success">
                    Example Success Response 200
                  </span>
                  <pre className="papr mt-2">
                    <code
                      dangerouslySetInnerHTML={{
                        __html: `{
  "type": "success",
  "message": "Bulk order added to queue"
}`,
                      }}></code>
                  </pre>
                  <span className="text-g text-danger">
                    Example Failed Response 400
                  </span>
                  <pre className="papr mt-2">
                    <code
                      dangerouslySetInnerHTML={{
                        __html: `{
  "type": "failed",
  "message": "message here"
}`,
                      }}></code>
                  </pre>
                </Section>
                <div className="border-btm pb-3 pt-2">
                  <Section title="7. Webhook response on CallbackUrl (BulkOrder)">
                    {/* <h4>1. Read LabelTypes</h4> */}
                    <span className="text-g">Endpoint:</span>
                    <pre className="papr mt-2">
                      <code className="language-bash">
                        Method: POST
                        {"\n"}URL:
                        {"{"}CallbackUrl{"}"} provided in the request while
                        creating bulk label
                      </code>
                    </pre>

                    <span className="text-g text-success">
                      Example Response Success
                    </span>
                    <pre className="papr mt-2">
                      <code
                        dangerouslySetInnerHTML={{
                          __html: `{
  status: 'completed',
  uuid: 'uuid',
  OrderId: "orderId",
  message: 'message here',
  pdf: 'pdf file url',
  zip: 'zip file url',
  csv: 'csv file url',
  orders: [orders],
  failedLabels:[failedLabels]
}`,
                        }}></code>
                    </pre>
                    <span className="text-g text-danger">
                      Example Response Failed
                    </span>
                    <pre className="papr mt-2">
                      <code
                        dangerouslySetInnerHTML={{
                          __html: `{
  status: "cancelled",
  OrderId: "",
  uuid: "",
  message: 'error message here,
  pdf: "",
  zip: "",
  csv: "",
  orders: [],
  failedLabels: [],
}`,
                        }}></code>
                    </pre>
                  </Section>
                </div>
                <Section title="8. Download Bulk Order">
                  {/* <h4>1. Read LabelTypes</h4> */}
                  <span className="text-g">Endpoint:</span>
                  <pre className="papr mt-2">
                    <code className="language-bash">
                      Method: GET
                      {"\n"}URL: {"{"}
                      {"{"}base_url{"}"}
                      {"}"}/download-bulk-order/
                      <span className="text-warning">
                        {"{"}
                        {"{"}bulkorder_uuid{"}"}
                        {"}"}
                      </span>
                      {"\n"}
                    </code>
                  </pre>

                  <span className="text-g my-2">Params</span>
                  <Table
                    headers={["Parameter", "Description", "Required"]}
                    maxW="500px">
                    <tr>
                      <td>
                        <span className="text-warning">
                          {"{"}
                          {"{"}bulkorder uuid{"}"}
                          {"}"}
                        </span>
                      </td>
                      <td>ID of bulk order to be downloaded</td>
                      <td>Yes</td>
                    </tr>
                  </Table>

                  <span className="text-g my-2">Example Request</span>
                  <pre className="papr">
                    <code
                      dangerouslySetInnerHTML={{
                        __html: `var axios = require('axios');
var data = '';

var config = {
  method: 'get',
  maxBodyLength: Infinity,
  url: 'https://api.ezyship.org/api/v2/order/download-bulk-order/48717510-020a-4af4-98bb-c902d7e7e5d8',
  headers: { 
    'x-api-key': '9a17182c-cddf-49e5-98b6-4b7a18bf1000'
  },
  data : data
};

axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
  })
  .catch(function (error) {
    console.log(error);
  });                                            
`,
                      }}></code>
                  </pre>
                </Section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
