import React, { useState, useRef } from "react";
import axios from "axios";
import { server, RECAPTCHA_KEY } from "../env";
import Loader from "../components/Loader";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../components/Alert";
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState("");
  const [message, setMessage] = useState("");
  const recaptchaRef = useRef(null);

  const login = async (e) => {
    e.preventDefault();
    const captchaToken = await recaptchaRef.current.getValue();
    recaptchaRef.current.reset();

    setLoader(<Loader />);
    const data = {
      email: e.target.email.value,
      password: e.target.password.value,
      captchaToken,
    };

    await axios
      .post(server + "/api/v1/auth/login", data)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        Cookies.set("token", res.data.accessToken);
        window.location.href = "/";
      })
      .catch((err) => {
        if (err.response.status === 403) {
          setMessage(
            <Alert className="danger" message={err.response.data.message} />
          );
          setLoader("");
          localStorage.setItem("email", data.email);
          setTimeout(() => {
            navigate("/verify");
          }, 1500);
        } else {
          setMessage(
            <Alert className="danger" message={err.response.data.message} />
          );
          setLoader("");
        }
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  return (
    <div className="nk-app-root">
      {/* main @s */}
      <div className="nk-main ">
        {/* wrap @s */}
        <div className="nk-wrap nk-wrap-nosidebar">
          {/* content @s */}
          <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
              <div className="brand-logo pb-4 text-center">
                <a href="html/index.html" className="logo-link">
                  <img
                    className="logo-dark logo-img logo-img-lg"
                    src="/logo.svg"
                    alt="logo-dark"
                  />
                </a>
              </div>
              <div className="card">
                <div className="card-inner card-inner-lg">
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h4 className="nk-block-title">Sign-In</h4>
                      <div className="nk-block-des"></div>
                    </div>
                  </div>
                  {message}
                  <form onSubmit={login}>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="default-01">
                          Email
                        </label>
                      </div>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="default-01"
                        name="email"
                        placeholder="Enter your email address "
                      />
                    </div>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                        <Link
                          to="/forgot"
                          className="link link-primary link-sm"
                          href="html/pages/auths/auth-reset-v2.html"
                        >
                          Forgot Password?
                        </Link>
                      </div>
                      <div className="form-control-wrap">
                        <span
                          className="form-icon form-icon-right "
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <em className="passcode-icon icon-show icon ni ni-eye" />
                          ) : (
                            <em className="passcode-icon icon-show icon ni ni-eye-off" />
                          )}
                        </span>
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control form-control-lg"
                          id="password"
                          name="password"
                          placeholder="Enter your password"
                        />
                      </div>
                    </div>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={RECAPTCHA_KEY}
                      className="w-100 mb-3"
                    />

                    <div className="form-group">
                      <button
                        className="btn btn-lg btn-primary btn-block"
                        type="submit"
                      >
                        Sign in {loader}
                      </button>
                    </div>
                  </form>
                  <div className="form-note-s2 text-center pt-4">
                    {" "}
                    New on our platform?{" "}
                    <Link to="/register">Create an account</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* wrap @e */}
        </div>
        {/* content @e */}
      </div>

      {/* main @e */}
    </div>
  );
};

export default Login;
